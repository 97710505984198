* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  padding-top: 70px;

  &.has-warning {
    padding-top: 120px;
  }
}

a {
  text-decoration: none;
}

main {
}
