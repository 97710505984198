.single-preview {
  &__content {
    width: 100%;

    &--palestra {
      @include media-breakpoint-up(lg) {
        min-height: 672px;
      }
    }

    &--artigointerno {
      @include media-breakpoint-up(lg) {
        min-height: 277px;
      }
    }
  }

  &__infos {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__numbers {
    display: inline-flex;
    align-items: center;
  }

  &__item {
    margin-right: 35px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    p {
      @include fontAlpha(13px, 700);
      color: $black;
    }
  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
      transform: translateY(-2px);
    }
  }
}
