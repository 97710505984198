.form-control {
  .input-text {
    width: 260px;
    height: 40px;
    border: none;
    background-color: transparent;
    padding: 13px 23px;
    @include fontAlpha(13px, 700);

    &--bg-white {
      background-color: $white;
      color: $black;
    }
  }
}