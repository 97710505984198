.container {
  max-width: 1360px;

  @include media-breakpoint-down(lg) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.container-single {
  @include media-breakpoint-up(lg) {
    padding: 0 85px;
  }
}
