.card-author {
  padding: 32px 32px;

  @include media-breakpoint-up(lg) {
    padding: 20px 32px;
  }

  &__content {
    display: flex;
  }

  &__title {
    @include fontAlpha(24px, 700);
    color: $black;
    margin-bottom: 37px;
  }

  &__elipsis {
    margin: 0 12px;
  }

  &__avatar {
    width: 89px;
    min-width: 89px;
    height: 89px;
    border-radius: 50%;
    margin-right: 23px;
    object-fit: cover;
  }

  &__base {
    display: flex;
    align-items: center;
    @include fontAlpha(16px, 700);
    line-height: 30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }

    &-name {
      min-width: 132px;
    }
  }

  &__description {
    max-width: 470px;
    @include fontAlpha(14px, 400);
    line-height: 24px;

    p {
      margin-top: 1em;
    }

    @include media-breakpoint-down(md) {
      display: block;
      margin-top: 15px;
      max-width: 100%;
    }
  }
}