.accordion {
  max-width: 865px;
  margin: 0 auto;
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    margin-top: 35px;
  }

  &__item {
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  }

  &__question {
    height: 60px;
    background-color: $gray;
    padding: 0 38px;
    text-align: left;
    width: 100%;
    display: block;
    border: none;
    outline: none;
    @include fontAlpha(14px, 700);
    color: $black;
    cursor: pointer;
    transition: color 300ms ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(16px, 700);
    }

    &-arrow {
      transform: rotate(90deg);
      transition: transform 300ms ease-in-out;
    }

    &.is-active {
      color: $pink;

      .accordion__question-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  &__answer {
    padding: 16px 40px 60px;
    background-color: $white;
    @include fontAlpha(14px, 400);
    line-height: 24px;
  }
}
