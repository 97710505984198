.cta {
  width: 100%;
  height: 100%;
  position: relative;

  .parallax {
    position: absolute;
    left: 180px;
    top: 340px;
    z-index: 1;

    &.right {
      left: initial;
      right: 30px;
      top: 430px;
    }

    .react-parallax {
      overflow: visible !important;

      &.fluid-1 {
        left: -140px;
        transform: rotate(25deg);
      }

      &.fluid-2 {
        bottom: 200px;
      }
    }
  }

  &__content {
    max-width: 860px;
    margin: 0 auto;
    padding: 60px 26px 80px;
  }

  &__group {
    padding-top: 50px;
    justify-content: center;
    align-items: center;
  }

  &__title {
    @include fontAlpha(24px, 700);
    line-height: 34px;
  }

  &__paragraph {
    @include fontAlpha(16px, 400);
    line-height: 30px;
  }

  &__btn-warning {
    @include fontAlpha(16px, 700);
    line-height: 26px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 50px;
    }
  }

  &__line {
    border-color: rgba(255, 255, 255, 0.4);

    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }
  

  @media (max-width: 1320px) {
    .parallax {
      display: none;
    }
  }

  @media (max-width: 1450px) {
    .parallax {
      top: 260px
    }
  }
}
