.category {
  &--podcast {
    background-color: $blue;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &--artigo {
    background-color: $green;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &--palestra {
    background-color: $pink;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}