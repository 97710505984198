.title {
  &--section {
    @include fontAlpha(32px, 700);
    text-align: center;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(48px, 700);
      text-align: left;
    }
  }

  &--single {
    @include fontAlpha(24px, 700);
    line-height: 28px;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(32px, 700);
      line-height: 32px;
    }
  }
}
