.single-content {
  margin-top: 22px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 61px;
    margin-bottom: 70px;
  }

  .row {
    max-width: 100%;
    margin: 0 auto;
  }

  &__col {
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding-right: 20px;
    }
  }

  .col-lg-5 {
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }

  &__description {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    h1, h2 {
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }

    h3 {
      @include fontAlpha(22px, 700);
      line-height: 30px;
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        @include fontAlpha(24px, 700);
        line-height: 30px;
        margin-bottom: 50px;
      }
    }

    h4 {
      @include fontAlpha(18px, 700);
      line-height: 30px;
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        @include fontAlpha(20px, 700);
        line-height: 30px;
        margin-bottom: 50px;
      }
    }

    p {
      @include fontAlpha(16px, 400);
      line-height: 30px;
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }

    audio {
      width: 100%;
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }

    img {
      max-width: 100%;
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }

    a {
      color: $blue;
      text-decoration: underline;
    }
  }

  &__author {
    padding-top: 32px;

    @include media-breakpoint-up(lg) {
      padding-top: 20px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
    }
  }

  &__title {
    @include fontAlpha(24px, 700);
    color: $black;
    margin-left: 32px;
    margin-bottom: 37px;

    @include media-breakpoint-up(lg) {
      margin-left: 20px;
    }
  }

  &__see-more {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid rgba($black, 0.2);
      padding-left: 33px;
      position: sticky;
      top: 100px;
    }

    &-text {
      @include fontAlpha(16px, 700);
      margin-bottom: 22px;
    }
  }
}