.btn {
  &--default {
    width: 290px;
    height: 50px;
    border: none;
    outline: none;
    @include fontAlpha(18px, 700);
    transition: transform 300ms ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1) translateY(2px);
    }

    @include media-breakpoint-up(lg) {
      width: 327px;
    }
  }

  &--medium {
    width: 142px;
    height: 40px;
    border: none;
    outline: none;
    @include fontAlpha(13px, 700);
    transition: transform 300ms ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.1) translateY(2px);
    }
  }

  &--share {
    width: 220px;
    height: 30px;
    border: none;
    outline: none;
    @include fontAlpha(13px, 700);
    transition: transform 300ms ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.1) translateY(2px);
    }
  }

  &--small {
    height: 15px;
    border: none;
    outline: none;
    padding: 0 14px;
    min-width: 71px;
    @include fontAlpha(9px, 700);
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(13px, 700);
      height: 25px;
      min-width: 136px;
    }
  }

  &--round {
    border-radius: 1000px;
  }

  &--outline-black {
    border: 1px solid $black;
  }
}
