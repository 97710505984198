@import '../../../../node_modules/slick-carousel/slick/slick.scss';

.single-carousel {
  margin-bottom: 120px;
  &__text {
    @include fontAlpha(24px, 700);
    line-height: 48px;
  }
}

.slick-slider {
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $white;
    padding: 0;
    border: 0;
    cursor: pointer;

    &.slick-next {
      right: -66px;
      background-image: url('../../images/icon-chevron-right-black.svg');
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
    }

    &.slick-prev {
      left: -66px;
      background-image: url('../../images/icon-chevron-left-black.svg');
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -46px;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;

    button {
      border: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba($black, 0.1);
      margin: 0 5px;
      padding: 0;
      font-size: 0;
      cursor: pointer;
      outline: none;
    }

    .slick-active {
      button {
        background-color: $black;
      }
    }
  }
}
