.single-header {
  margin: 56px 0 26px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 70px;
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__group {
    display: flex;
    justify-content: center;
    margin-left: 20px;

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__back {
    @include fontAlpha(16px, 700);

    &-arrow {
      transform: rotate(180deg) translateY(-1px);
      width: 8px;
      height: 14px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
}
