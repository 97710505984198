.faq {
  padding: 60px 0 40px;
  position: relative;

  .parallax {
    position: absolute;
    right: 30px;
    bottom: 20px;

    .react-parallax {
      overflow: visible !important;

      &.fluid-3 {
        right: 100px;
      }

      &.fluid-4 {
        bottom: 350px;
      }
    }
  }

  @media (max-width: 1320px) {
    .parallax {
      display: none;
    }
  }
}