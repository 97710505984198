.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 50px 110px;

  @include media-breakpoint-up(lg) {
    height: 159px;
    justify-content: center;
    padding: 0;
  }

  &__text {
    @include fontAlpha(10px, 700);
    line-height: 20px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(13px, 700);
      text-align: left;
      line-height: 26px;
    }
  }
}