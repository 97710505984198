.buttonscrolltop {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 21px;
  bottom: 20px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  border: 0;
  transition: all 0.3s;
  z-index: 500;

  @include media-breakpoint-up(lg) {
    right: 56px;
  }

  &:hover,
  &:focus,
  &.active {
    outline: none;
    transform: scale(0.9);
  }

  &__chevron {
    margin-top: 3px;
    width: 11px;
    height: 20px;
    transform: rotate(90deg);
    margin-bottom: 2px;
  }

  &__text {
    text-align: center;
    color: black;
    @include fontAlpha(11px, 700);
    line-height: 11px;
  }
}

.hide {
  opacity: 0;
}
