.header {
  position: fixed;
  width: 100%;
  top: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;

  @include media-breakpoint-up(lg) {
  }

  &__content {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $white;
    padding: 0 20px;
    align-items: center;

    @include media-breakpoint-up(lg) {
      padding: 0 60px;
    }
  }

  &__hamburger {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
    }
  }

  &__menu {
    height: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $pink;
      transform: translateX(100%);
      transition: transform 0.3s ease;
    }

    &.active {
      transform: translateX(0);
    }

    &-header {
      display: none;
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        height: 60px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      }

      .header__hamburger {
        @include media-breakpoint-down(md) {
          position: relative;
          top: 4px;
        }
      }
    }

    .logo-talks {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: auto;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__nav {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      overflow: hidden;
      max-width: 100%;
      height: 100%;
    }

    li {
      height: 100%;

      @include media-breakpoint-down(md) {
        height: 17vh;
        width: 95%;
        justify-content: center;
        display: flex;
        color: $white;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      }
    }
  }

  &__nav-item {
    color: $white;
    background-color: transparent;
    text-decoration: none;
    @include fontAlpha(32px, 700);
    line-height: 24px;
    position: relative;
    transition: 0.3s;
    z-index: 1;
    margin: 0 50px;
    display: flex;
    height: 100%;
    align-items: center;
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      background-color: #fff;
      color: $black;
      @include fontAlpha(20px, 700);
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #dfe2ea;
      opacity: 0;
      transition: 0.3s;
    }

    &:not(.is-active):hover:before {
      opacity: 1;
      bottom: 0;
    }
    &:not(.is-active):hover {
      color: #333;
    }

    &.header-active {
      &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        transition: 0.4s;
        height: 5px;
        z-index: 1;
        background-color: $pink;
        opacity: 1;
        bottom: 0;
      }
    }
  }
}
