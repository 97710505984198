@import '../../../node_modules/bootstrap/scss/variables';

$red: #da291c;
$black: #222222;
$pink: #ff218c;
$white: #ffffff;
$blue: #0517f0;
$gray: #f8f8f8;
$green: #33CD29;

$colors: ('red': $red,
  'black': $black,
  'pink': $pink,
  'white': $white,
  'blue': $blue,
  'gray': $gray,
  'green': $green);

@each $name,
$hash in $colors {
  .bg-#{$name} {
    background-color: $hash !important;
  }

  .color-#{$name} {
    color: $hash !important;
  }

  .outline-#{$name} {
    border-color: $hash !important;
  }
}

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 5px;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
    1: ($spacer * 1,
    ),
    2: ($spacer * 2,
    ),
    3: ($spacer * 3,
    ),
    4: ($spacer * 4,
    ),
    5: ($spacer * 5,
    ),
    6: ($spacer * 6,
    ),
    7: ($spacer * 7,
    ),
    8: ($spacer * 8,
    ),
    9: ($spacer * 9,
    ),
    10: ($spacer * 10,
    ),
  ),
  $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge((25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes);

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1260px,
  xl: 1400px);