.events {
  padding: 70px 0 60px;

  &__list {
    margin-bottom: 60px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($black, .2);
    }
  }


  &__week {
    &-name {
      @include fontAlpha(16px, 700);
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &-icon {
      margin-right: 8px;
      transform: translateY(-1px);
    }
  }

  &__itens {
    margin-bottom: 36px;
  }
}