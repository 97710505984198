.datetime {
  span {
    display: flex;
    align-items: center;
    @include fontAlpha(9p, 700);

    @include media-breakpoint-up(lg) {
      @include fontAlpha(13px, 700);
    }
  }

  &__calendar {
    margin-right: 13px;
    width: 9px;
    height: 9px;

    @include media-breakpoint-up(lg) {
      width: auto;
      height: auto;
    }
  }

  &__elipse {
    margin: 0 8px;
    width: 3px;
    height: 3px;

    @include media-breakpoint-up(lg) {
      width: auto;
      height: auto;
    }
  }
}
