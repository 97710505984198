.modal {
  &-dialog {
    max-width: 900px;
  }

  &__content {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      padding: 78px 204px 56px;
    }
  }

  &__form {
    width: 100%;

    @include media-breakpoint-down(lg) {
      padding: 80px 15px;
    }

    label,
    input {
      display: block;
    }

    label {
      @include fontAlpha(24px, 700);
      color: white;
      margin-bottom: 25px;
    }

    input {
      width: 100%;
      height: 50px;
      margin-bottom: 25px;
      padding: 12px 25px;
      background: rgba($white, 0.2);
      @include fontAlpha(16px, 700);
      line-height: 34px;
      border: 0;
      color: white;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    button {
      margin-top: 50px;
      width: 189px;
    }

    .alert {
      @include fontAlpha(16px, 500);
      @include border-radius(0);

      line-height: 24px;
      padding: 12px 25px;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 16px;
    border: 0;
    background: none;
    cursor: pointer;
  }

  &__feedback {
    &-title {
      margin-top: 156px;
      @include fontAlpha(18px, 700);
      line-height: 28px;

      @include media-breakpoint-up(lg) {
        @include fontAlpha(23px, 700);
        line-height: 34px;
        margin-top: 0px;
      }
    }

    &-subtitle {
      @include fontAlpha(18px, 400);
      line-height: 28px;
      padding-left: 45px;
      padding-right: 45px;

      @include media-breakpoint-up(lg) {
        padding-left: opx;
        padding-right: 0px;
        @include fontAlpha(23px, 400);
        line-height: 34px;
      }
    }

    button {
      max-width: 189px;
      margin-bottom: 118px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0px;
      }
    }
  }

  &--register {
    .modal__content {
      position: relative;
      z-index: 2;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 150px;
        height: 130px;
        background-image: url("../../images/modal-register-left-mobile.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: -1;

        @include media-breakpoint-up(lg) {
          height: 260px;
          background-image: url("../../images/modal-register-left.png");
        }
      }

      &:before {
        @include media-breakpoint-up(lg) {
          content: "";
          position: absolute;
          top: 81px;
          right: 0;
          width: 170px;
          height: 260px;
          background-image: url("../../images/modal-register-right.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }

  &--share {
    .modal__content {
      position: relative;
      z-index: 2;

      @include media-breakpoint-down(md) {
        padding: 100px 15px 105px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 150px;
        height: 130px;
        background-image: url("../../images/modal-register-left-mobile.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: -1;

        @include media-breakpoint-up(lg) {
          height: 260px;
          background-image: url("../../images/modal-share-left.png");
        }
      }

      &:before {
        @include media-breakpoint-up(lg) {
          content: "";
          position: absolute;
          top: 81px;
          right: 0;
          width: 170px;
          height: 260px;
          background-image: url("../../images/modal-share-right.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }

    &__title {
      @include fontAlpha(24px, 700);
      margin-bottom: 28px;
      color: $white;

      @include media-breakpoint-up(lg) {
      }
    }

    &__social-list {
      margin-bottom: 30px;

      li {
        margin-right: 27px;
        display: inline-block;

        button {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 0;

          &:hover,
          &:focus,
          &.active {
            outline: none;
          }
        }
      }
    }

    &__box-link {
      @include fontAlpha(16px, 500);
      padding: 15px 25px;
      background: lighten($blue, 10%);
      color: $white;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
      }
    }

    &__btn-copy {
      @include fontAlpha(13px, 700);
      color: $white;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
      }

      &:hover {
        color: $white;
      }
    }
  }

  &__terms {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label {
      font-size: 14px;
    }

    input, label {
      margin-bottom: 11px;
    }
    
    button, a {
      margin-bottom: 8px;
      background: transparent;
      display: inline-block;
      border: 0;
      font-family: 'Montserrat', sans-serif;
      padding: 0;
      text-decoration: underline;
      font-size: 14px;
      margin-left: 5px;
      color: #fff;
      font-weight: bold;
      width: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }
}
