.live-warning {
  display: none;
  align-items: center;
  height: 50px;
  background-color: $pink;
  color: $white;
  @include fontAlpha(9px, 700);
  padding: 0 13px;

  &.is-active {
    display: flex;
  }

  @include media-breakpoint-up(lg) {
    @include fontAlpha(13px, 700);
    padding: 0 60px;
    justify-content: space-between;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      flex: 2;
      flex-direction: row;
      align-items: center;
    }
  }

  &__left {
    padding-left: 15px;
    position: relative;
    text-transform: uppercase;
    margin-right: 10px;

    @include media-breakpoint-up(lg) {
      flex: 1;
      margin-right: 0;
      max-width: 200px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      display: block;
      background-color: $white;
      top: 2px;
      left: 0;
      border-radius: 50px;
      animation: blink 1.6s linear infinite;
      opacity: .5;

      @include media-breakpoint-up(lg) {
        width: 10px;
        height: 10px;
      }
    }
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  &__right {
    text-transform: uppercase;
    color: $white;

    @include media-breakpoint-up(lg) {}
  }

  &__msg {
    @include fontAlpha(10px, 700);
    margin-bottom: 5px;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(15px, 700);
      margin-bottom: 0;
    }
  }

  &__link {
    color: $white;
    text-decoration: underline;

    @include media-breakpoint-up(lg) {
      margin-right: 20px;
    }
  }

  &__close {
    padding: 0;
  }
}