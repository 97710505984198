.event {
  &__group {
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__link {
    display: flex;
    align-items: flex-end;
    position: relative;

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: auto;
    }
  }

  &__bg {
    max-width: 100%;
    height: auto;
    z-index: 9;
    transition: all 250ms ease-in-out;
  }

  &__content {
    padding: 18px 15px;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 250ms ease-in-out;
    
    @include media-breakpoint-up(lg) {
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 200%;
    width: 100%;
    height: 100%;
    background-color: rgba($pink, 0.7);
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 350ms cubic-bezier(0.01, 1.3, 0.55, 0.98);

    @include media-breakpoint-up(lg) {
    }

    &-img {
      margin-bottom: 5px;
      width: 30px;
      height: 30px;
      object-fit: cover;

      @include media-breakpoint-up(lg) {
      }
    }

    &-text {
      @include fontAlpha(30px, 700);
    }
  }

  &__infos {
    margin-bottom: 13px;
    display: flex;
    align-items: center;
  }

  &__text {
    @include fontAlpha(16px, 700);
    line-height: 22px;
    color: $white;

    @include media-breakpoint-up(lg) {
      @include fontAlpha(20px, 700);
      line-height: 26px;
    }
  }

  &__warning {
    position: absolute;
    top: 0;
    z-index: 12;
    background: $pink;
    color: $white;
    width: 100%;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    filter: grayscale(1);
    height: 30px;
    text-align: center;
    text-transform: uppercase;
    @include fontAlpha(13px, 700);
    pointer-events: none;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
    }
  }

  &:hover {
    .event__content,
    .event__bg {
      filter: blur(2px);
    }

    .event__overlay {
      top: 0;
    }
  }

  &.is-disabled {
    pointer-events: none;

    .event__warning {
      opacity: 1;
      filter: grayscale(0);
    }

    .event__link {
      filter: grayscale(100%);
    }

    &:hover {
      .event__content,
      .event__bg {
        filter: none;
      }

      .event__overlay {
        display: none;
      }
    }

    .event__overlay {
      display: none;
    }
  }

  &--sm {
    .event__link {
      max-width: 100%;
      height: auto;
      
      @include media-breakpoint-up(lg) {
      }
    }

    .event__text {
      font-size: 16px;
      line-height: 20px;

      @include media-breakpoint-up(lg) {
      }
    }
  }
}
