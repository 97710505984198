.search-bar {
  display: none;
  position: relative;
  width: 335px;
  height: 30px;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &:after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 18px;
    height: 18px;
    background-image: url("../../images/magnifying-glass.svg");
    background-position: center;
    background-size: contain;

    @include media-breakpoint-up(lg) {
    }
  }

  &__input {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 17px;
    border: 1px solid $pink;
    text-align: left;
    padding: 7px 14px;

    @include media-breakpoint-up(lg) {
    }
  }
}
