.hero {
  position: relative;
  display: flex;

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;

    @include media-breakpoint-up(lg) {
    }
  }

  &__logo__container {
    text-align: center;
    margin-top: 25%;
    @media screen and (min-width: 550px) {
      text-align: right;
      width: 50%;
      margin-top: 8%;
    }
  }
  &__logo {
    height: 120px;

    @include media-breakpoint-up(lg) {
      height: 200px;
    }
  }

  &__content {
    position: relative;
    z-index: 5;
    height: 100%;
    min-height: 585px;
    max-height: 585px;
    width: 100%;
    background: url("../../images/lp-mobile.png") no-repeat;
    background-size: cover;

    @media screen and (min-width: 550px) {
      display: flex;
      justify-content: center;
      min-height: 730px;
      max-height: 730px;
      height: 730px;
      background: url("../../images/lp-desktop.png") center bottom no-repeat;
      background-size: cover;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 80px;
      background: rgb(2, 0, 36);
      background: linear-gradient(360deg, rgba(2, 0, 36, 0) 0%, rgba(5, 23, 240, 1) 0%, rgba(0, 212, 255, 0) 100%);
      z-index: 1;
      display: block;
    }
  }

  &__text {
    width: 90%;
    margin: 5%;
    text-align: center;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 550px) {
      width: 50%;
      margin-top: 14%;
      margin-left: 5%;
      text-align: left;
    }
  }

  &__title {
    @include fontAlpha(28px, 700);
    line-height: 34px;
    text-align: center;
    display: none;
    @media screen and (min-width: 550px) {
      display: block;
      @include fontAlpha(40px, 700);
      line-height: normal;
      text-align: left;
    }
  }

  &__description {
    @include fontAlpha(28px, 700);
    line-height: 34px;
    text-align: center;
    font-size: 25px;

    .break-class {
      display: block;
      @media screen and (max-width: 549px) {
        margin: 5px auto;
        width: 260px;
      }
    }

    @media screen and (min-width: 550px) {
      @include fontAlpha(20px, 700);
      line-height: normal;
      text-align: left;
    }
  }

  &__button {
    font-size: 23px;
    border-radius: 2px;
    border: 0;
    box-shadow: none;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 15px !important;

    @media screen and (max-width: 549px) {
      margin: 0 auto;
    }
  }
}
