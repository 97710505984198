.newsletter {
  width: 100%;

  &__title {
    @include fontAlpha(24px, 700);
    line-height: 34px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;

    @include media-breakpoint-up(lg) {
      height: 300px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: transparent;
    border: none;
    height: 100%;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      height: initial;
      align-items: flex-start;
    }

    .form-row {
      margin: 0 0.5em;
      flex-direction: column;
    }

    &__terms {
      min-width: 256px;
      @include fontAlpha(14px, 400);
      color: white;
      label {
        margin-left: 3px;
        cursor: pointer;
      }
      input {
        cursor: pointer;
        /* Double-sized Checkboxes */
        -ms-transform: scale(1.2); /* IE */
        -moz-transform: scale(1.2); /* FF */
        -webkit-transform: scale(1.2); /* Safari and Chrome */
        -o-transform: scale(1.2); /* Opera */
        transform: scale(1.2);
      }
      button, a {
        display: block;
        @include fontAlpha(14px, 400);
        padding: 7px 0 0 25px;
        text-decoration: underline;
        background: none;
        font-family: 'Montserrat', sans-serif;
        color: white;
        border: 0;
        box-shadow: none;
        cursor: pointer;
      }
      .alert {
        display: inline-block;
      }
    }
  }

  .alert {
    @include fontAlpha(13px, 500);
    @include border-radius(0);

    margin: 1em 0 0;
    padding: 13px 23px;
    line-height: 100%;
  }
}
